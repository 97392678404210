<template>
    <form class="send-form">
      <div class="form-group">
        <label>Номер телефона</label>
        <div class="input-group mb-3">
          <input
            ref="phoneRef"
            type="text"
            v-model="phone"
            class="form-control"
            v-mask="'+7(###)-###-##-##'"
            placeholder="+7(###)-###-##-##"
          />
          <div class="input-group-append">
            <button v-if="verifyTimer>0" class="btn" type="button">Войти ({{verifyTimer}})</button>
            <button v-else class="btn" type="button" @click="handleSendCode">Войти</button>
          </div>
        </div>
        <p class="error" v-if="error">{{errorText}}</p>
      </div>
    </form>
</template>

<script>
import { onMounted, inject, ref, computed } from 'vue';
import { sendCodeRequest } from "../../api/auth";

export default {
    name: 'SendFormComponent',
    setup() {

        const phoneRef = ref(null);
        const error = ref(false);
        const errorText = ref('');
        const phone = inject('phone');
        const getPhone = inject('getPhone');
        const loader = inject('loader');
        const verify = inject('verify');

        const initTimer = () => {
            const nowDate = Math.floor(new Date().getTime() / 1000);
            verify.value.timer = 120-(nowDate-verify.value.sendTime);
            if(verify.value.timer>0) {
                verify.value.timerInterval = setInterval(() => {
                    if(verify.value.timer<=0) {
                        clearTimeout(verify.value.timerInterval);
                        return
                    }
                    verify.value.timer--;
                }, 1000);
            }
        };

        const handleSendCode = () => {
            if(phone.value === '') return;
            loader.value = true;
            error.value = false;
            errorText.value = '';
            sendCodeRequest({
                phone: getPhone()
            }).then(response => {
                const data = response.data;
                loader.value = false;
                if(data.error) {
                    error.value = true;
                    errorText.value = data.error['loginbyphoneform-phone'][0];
                }
                verify.value.status = true;
                verify.value.sendTime = data.time;
                initTimer();
            }).catch(err => {
                console.log(err);
                loader.value = false;
            });
        };

        onMounted(() => {
            phoneRef.value.focus();
        });

        return {
            phone,
            phoneRef,
            handleSendCode,
            error,
            errorText,
            verifyTimer: computed(() => verify.value.timer),
        }
    }
}
</script>

<style scoped>

  .form-group label {
    font-weight: 400;
  }

  .form-group button {
    font-size: 10px;
    background-color: #3d7fa2;
    color: #ffffff;
    text-transform: uppercase;
  }

  .form-group input {
    text-align: center;
    margin: 0 auto;
    max-width: 230px;
  }

  .form-group .input-group {
    margin: 0 auto;
  }

  .form-group:last-child {
    margin-bottom: 0;
  }

  .error {
    max-width: 200px;
    margin: 0 auto;
  }

</style>
